var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "480"
    },
    on: {
      "input": _vm.close
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.save.apply(null, arguments);
      }
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-connection")]), _vm._v(" Kapcsolat létrehozása "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-select', {
    attrs: {
      "label": "Átjáró",
      "error-messages": _vm.errors.gateway_id,
      "items": _vm.gateways,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.connection.gateway_id,
      callback: function ($$v) {
        _vm.$set(_vm.connection, "gateway_id", $$v);
      },
      expression: "connection.gateway_id"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.$config.inputMasks.name,
      expression: "$config.inputMasks.name"
    }],
    attrs: {
      "label": "Név",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.connection.name,
      callback: function ($$v) {
        _vm.$set(_vm.connection, "name", $$v);
      },
      expression: "connection.name"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.$config.inputMasks.ipAddress,
      expression: "$config.inputMasks.ipAddress"
    }],
    attrs: {
      "label": "IP cím",
      "error-messages": _vm.errors.ip,
      "filled": ""
    },
    model: {
      value: _vm.connection.ip,
      callback: function ($$v) {
        _vm.$set(_vm.connection, "ip", $$v);
      },
      expression: "connection.ip"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.$config.inputMasks.integerNumber,
      expression: "$config.inputMasks.integerNumber"
    }],
    attrs: {
      "label": "Port",
      "error-messages": _vm.errors.port,
      "filled": ""
    },
    model: {
      value: _vm.connection.port,
      callback: function ($$v) {
        _vm.$set(_vm.connection, "port", $$v);
      },
      expression: "connection.port"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.$config.inputMasks.name,
      expression: "$config.inputMasks.name"
    }],
    attrs: {
      "label": "RS232 Port",
      "error-messages": _vm.errors.rs232_port_dev_name,
      "filled": ""
    },
    model: {
      value: _vm.connection.rs232_port_dev_name,
      callback: function ($$v) {
        _vm.$set(_vm.connection, "rs232_port_dev_name", $$v);
      },
      expression: "connection.rs232_port_dev_name"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.$config.inputMasks.createNumberMask({
        min: 9600,
        max: 921600
      }),
      expression: "$config.inputMasks.createNumberMask({ min: 9600, max: 921600 })"
    }],
    attrs: {
      "label": "RS232 Baud rate",
      "error-messages": _vm.errors.rs232_baud_rate,
      "filled": ""
    },
    model: {
      value: _vm.connection.rs232_baud_rate,
      callback: function ($$v) {
        _vm.$set(_vm.connection, "rs232_baud_rate", $$v);
      },
      expression: "connection.rs232_baud_rate"
    }
  }), _c('v-textarea', {
    attrs: {
      "label": "Megjegyzés",
      "filled": "",
      "rows": "3",
      "auto-grow": ""
    },
    model: {
      value: _vm.connection.comment,
      callback: function ($$v) {
        _vm.$set(_vm.connection, "comment", $$v);
      },
      expression: "connection.comment"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "primary",
      "type": "submit",
      "loading": _vm.loading
    }
  }, [_vm._v("Mentés")])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }