<template>
  <v-dialog v-model="dialog" @input="close" max-width="480">
    <form @submit.prevent="save">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-connection</v-icon>
          Kapcsolat létrehozása
          <v-spacer />
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-select label="Átjáró" :error-messages="errors.gateway_id" v-model="connection.gateway_id" :items="gateways"
            item-text="name" item-value="element_id" filled :value-comparator="$config.comparators.isEqual" />

          <v-text-field v-model="connection.name" label="Név" :error-messages="errors.name" filled v-mask="$config.inputMasks.name" />

          <v-text-field v-model="connection.ip" label="IP cím" :error-messages="errors.ip" filled v-mask="$config.inputMasks.ipAddress" />

          <v-text-field v-model="connection.port" label="Port" :error-messages="errors.port" filled v-mask="$config.inputMasks.integerNumber" />

          <v-text-field v-model="connection.rs232_port_dev_name" label="RS232 Port" :error-messages="errors.rs232_port_dev_name" filled
            v-mask="$config.inputMasks.name" />

          <v-text-field v-model="connection.rs232_baud_rate" label="RS232 Baud rate" :error-messages="errors.rs232_baud_rate" filled
            v-mask="$config.inputMasks.createNumberMask({ min: 9600, max: 921600 })" />

          <v-textarea v-model="connection.comment" label="Megjegyzés" filled rows="3" auto-grow />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn color="primary" type="submit" class="ma-2" :loading="loading">Mentés</v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean
  },

  data: () => ({
    dialog: false,
    loading: false,
    errors: {},
    connection: {
      name: '',
      ip: '',
      port: '',
      gateway_id: null,
      rs232_port_dev_name: '',
      rs232_baud_rate: '',
      comment: '',
    },
    gateways: [],
  }),

  watch: {
    value: {
      immediate: true,

      handler(value) {
        this.dialog = value;

        if (value) {
          this.fetchGateways();
        }
      },
    },
  },

  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },


    async fetchGateways() {
      const response = await this.$http.get(`gateways/list`);
      this.gateways = response.gateways;
    },

    async save() {
      try {
        this.loading = true;
        this.errors = {};

        const response = await this.$http.post('connections/save', this.connection);
        if (response.status === 'OK' && Object.entries(response.errors).length === 0) {
          this.$emit('save', response.connection);
          this.close();
          this.connection = {};
          this.$dialog.notify.success('Sikeres mentés!');
        } else {
          this.errors = response.errors;
        }
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>